import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactHeader from "../Contact/ContactHeader";
import Footer from "../Footer/Footer";
import "./Pricing.css";

function Pricing() {
  const [isMonthlyActive, setIsMonthlyActive] = useState(true);

  const handleToggleChange = () => {
    setIsMonthlyActive(!isMonthlyActive);
  };

  const handleGetStarted = (packageName, monthlyPrice, yearlyPrice) => {
    const pricingType = isMonthlyActive ? "Monthly" : "Yearly";
    const packageAmount = isMonthlyActive ? monthlyPrice : yearlyPrice;

    console.log(
      `Package Name: ${packageName}, Pricing Type: ${pricingType}, Package Amount: ${packageAmount}`
    );
    localStorage.setItem(
      "selectedPackage",
      JSON.stringify({ packageName, pricingType, packageAmount })
    );
  };

  return (
    <>
      <ContactHeader />
      {/* <!-- Hero Area============================================ --> */}

      <div className="fix p-5 bannermobi" id="Pricing">
        {/* Toggle Switch */}
        <div className="containertog toggle-switch">
          <p className="ptogle">Yearly</p>
          <input
            type="checkbox"
            id="toggle"
            checked={isMonthlyActive}
            onChange={handleToggleChange}
          />
          <label htmlFor="toggle">Toggle</label>
          <p className="ptogle">Monthly</p>
        </div>
        {/* Tab panes */}
        <div className="tab-content text-center">
          {isMonthlyActive ? (
            <div className="tab-pane active" id="home" role="tabpanel">
              <div className="plans flow-content">
                <div className="plan plan--light">
                  <h2 className="plan-title">basic</h2>
                  <p className="plan-price">
                    $ 149
                  </p>
                  <p className="plan-title">Basic Package for Single Practitioner</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">1 Practitioner</li>
                    <li id="basic" className="price bottom-bar">Practitioner Portal</li>
                    <li className="bottom-bar">Unlimited Appointments</li>
                    <li className="bottom-bar">In-App Messaging</li>
                    <li className="bottom-bar">Video Calling</li>
                    <li className="bottom-bar">Advanced Appointments Management</li>
                    <li className="bottom-bar">Visibility to Patient's Map</li>
                    <li className="bottom-bar">Healthcare Trained AI-Chatbot</li>
                  </ul>
                  <Link
                    to="/DrForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted(
                        "Basic Package",
                        "$149/month",
                        "$1,499/year"
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Standard</h2>
                  <p className="plan-price">
                    $ 299
                  </p>
                  <p className="plan-title">Everything in Basic Plus</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">2-5 Practitioners</li>
                    <li id="basic" className="price bottom-bar">Clinic Portal Access</li>
                    <li className="bottom-bar">Advanced Scheduling System</li>
                    <li className="bottom-bar">Appointments Management</li>
                    <li className="bottom-bar">Practitioners Activity</li>
                    <li className="bottom-bar">Revenue Monitoring</li>
                    <li className="bottom-bar">Excel Exportable Data</li>
                    <li className="bottom-bar">Patients Management</li>
                  </ul>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted("Standard", "$299/month", "$2,999/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Premium</h2>
                  <p className="plan-price">
                    $ 495
                  </p>
                  <p className="plan-title">Standard but with Higher Limits</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">6-25 Practitioners</li>
                    <li id="basic" className="price bottom-bar">Clinic Portal Access</li>
                    <li className="bottom-bar">Advanced Scheduling System</li>
                    <li className="bottom-bar">Appointments Management</li>
                    <li className="bottom-bar">Practitioners Activity</li>
                    <li className="bottom-bar">Revenue Monitoring</li>
                    <li className="bottom-bar">Excel Exportable Data</li>
                    <li className="bottom-bar">Patients Management</li>
                  </ul>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted("Premium", "$495/month", "$4,950/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            <div className="tab-pane active" id="profile" role="tabpanel">
              <div className="plans flow-content">
                <div className="plan plan--light">
                  <h2 className="plan-title">basic</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $ 1,499
                  </p>
                  <p className="plan-title">Basic Package for Single Practitioner</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">1 Practitioner</li>
                    <li id="basic" className="price bottom-bar">Practitioner Portal</li>
                    <li className="bottom-bar">Unlimited Appointments</li>
                    <li className="bottom-bar">In-App Messaging</li>
                    <li className="bottom-bar">Video Calling</li>
                    <li className="bottom-bar">Advanced Appointments Management</li>
                    <li className="bottom-bar">Visibility to Patient's Map</li>
                    <li className="bottom-bar">Healthcare Trained AI-Chatbot</li>
                  </ul>
                  <Link
                    to="/DrForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted(
                        "Basic Package",
                        "$149/month",
                        "$1,499/year"
                      )
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Standard</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $ 2,999
                  </p>
                  <p className="plan-title">Everything in Basic Plus</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">2-5 Practitioners</li>
                    <li id="basic" className="price bottom-bar">Clinic Portal Access</li>
                    <li className="bottom-bar">Advanced Scheduling System</li>
                    <li className="bottom-bar">Appointments Management</li>
                    <li className="bottom-bar">Practitioners Activity</li>
                    <li className="bottom-bar">Revenue Monitoring</li>
                    <li className="bottom-bar">Excel Exportable Data</li>
                    <li className="bottom-bar">Patients Management</li>
                  </ul>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted("Standard", "$299/month", "$2,999/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
                <div className="plan plan--light">
                  <h2 className="plan-title">Premium</h2>
                  <div className="payment-discount-tag">
                    <span className="discount-label">Discount</span>
                    <span className="discount-value">16%</span>
                  </div>
                  <p className="plan-price pt-3">
                    $ 4,950
                  </p>
                  <p className="plan-title">Standard but with Higher Limits</p>
                  <ul className="plan-price-ul">
                    <li className="bottom-bar">2-5 Practitioners</li>
                    <li id="basic" className="price bottom-bar">Clinic Portal Access</li>
                    <li className="bottom-bar">Advanced Scheduling System</li>
                    <li className="bottom-bar">Appointments Management</li>
                    <li className="bottom-bar">Practitioners Activity</li>
                    <li className="bottom-bar">Revenue Monitoring</li>
                    <li className="bottom-bar">Excel Exportable Data</li>
                    <li className="bottom-bar">Patients Management</li>
                  </ul>
                  {/* Uncomment the following Link if you want to enable it */}
                  <Link
                    to="/ClinicForm"
                    className="plan-btn"
                    onClick={() =>
                      handleGetStarted("Premium", "$495/month", "$4,950/year")
                    }
                  >
                    Get Started
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default Pricing;
