import React from "react";

const About = () => {
  return (
    <>
      {/* <!-- Feature Area============================================ --> */}
      <div
        id="feature-area"
        className="feature-area  pt-30 pb-30"
        style={{
          backgroundImage:
            "url(/assest/images/HomeImages/time-healing-concept-top-view.png)",
          backgroundColor: "white",
        }}
      >
        <div className="container">
          {/* <!-- Section Title --> */}
          <div className="row">
            <div className="col-md-6">
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <h1
                  className="mobimedical"
                  style={{
                    fontSize: "71px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    color: "#4b4b4bf5",
                  }}
                  data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom"
                >
                  AI-driven Healthcare at Your Fingertips Available
                </h1>
                <h1
                  className="mobimedicalnum"
                  style={{
                    fontSize: "71px",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                    color: "#50cada",
                  }}
                  data-aos="fade-left"
                >
                  24/7
                </h1>
                <p
                  style={{
                    fontSize: "20px",
                    color: "black",
                    fontFamily: "sans-serif",
                  }}
                  data-aos="flip-right"
                >
                  Virtual Triage helps clinics by automating many administrative
                  tasks through AI, reducing the strain on staff. The platform's
                  secure e-payments and e-prescription features further
                  streamline operations, allowing clinics to improve efficiency
                  and focus on delivering quality care, while saving time and
                  costs.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mainicon mobimainicon" data-aos="flip-right">
                <img
                  src="/assest/images/HomeImages/Layer10.png"
                  alt="digital patient care doctor HIPAA secure"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
