import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Header";
import AOS from "aos";
import axios from "axios";
import "../AllSection/HomeSec/ResponsiveWeb.css";
import "./Banner.css";
import Swal from "sweetalert2";

const Banner = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [email, setEmail] = useState("");
  const [currentSlide, setCurrentSlide] = useState(0);

  const images = [
    "/assest/images/branding/cup.png",
    "/assest/images/branding/Ribbon.png",
    "/assest/images/branding/pen.png",
    "/assest/images/branding/notebook-01.jpg",
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % images.length);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 200,
    });

    // Open the popup automatically after 5 seconds (5000 milliseconds)
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    // Automatically slide to the next image every 3 seconds (3000 milliseconds)
    const slideInterval = setInterval(() => {
      handleNextSlide();
    }, 3000);

    return () => {
      clearTimeout(timer);
      clearInterval(slideInterval);
    };
  }, []);

  const openCalendlyPopup = () => {
    window.Calendly.initPopupWidget({
      url: "https://api.leadconnectorhq.com/widget/booking/biUyOgLYwahf5Lmfbqnv",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make a POST request to your backend API with the email data
      await axios.post("https://portal.virtualtriage.ca/addcookies", { email });
      // Reset the email state and close the popup after successful submission
      setEmail("");
      setShowPopup(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Thank you for subscribing to our newsletter!",
      });
    } catch (error) {
      console.error("Error submitting email:", error);
      // Handle error appropriately, e.g., show error message to the user
    }
  };

  const handlePopupClose = () => {
    // Reset the timer when the popup is closed
    setShowPopup(false);
    // setTimeout(() => {
    //   setShowPopup(true);
    // }, 9000);
  };

  return (
    <>
      <div className="fix col-lx-12 col-lg-12 main-banner pb-20">
        <Header />
        <div className="container">
          <div className="hero-slide-content text-right">
            <div className="row">
              <div
                className="col-md-6 col-lx-6 col-lg-6"
                data-aos="fade-up"
                data-aos-easing="ease-out-cubic"
                data-aos-duration="3000"
              >
                <img
                  alt="Digital Health"
                  className="mobibanner"
                  src="/assest/images/HomeImages/Banner-vt-Correction.png"
                  style={{
                    marginTop: "-25px",
                    scale: "1.3",
                  }}
                />
              </div>
              <div className="col-md-6 col-lx-6 col-lg-6">
                <div data-aos="zoom-out-left">
                  <h1
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    data-aos="fade-up"
                    className="bannername typewriter"
                  >
                    AI-Enhanced
                  </h1>

                  <h1
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    className="bannername2"
                  >
                    Personalized
                  </h1>
                  <h2
                    style={{
                      textAlign: "left",
                      textTransform: "capitalize",
                      letterSpacing: "1px",
                      fontSize: "62px",
                      fontFamily: "sans-serif",
                      marginLeft: "72px",
                      fontWeight: "700",
                    }}
                    className="bannername2"
                  >
                    Healthcare
                  </h2>
                </div>
                <div
                  style={{
                    textAlign: "start",
                    marginLeft: "75px",
                    fontFamily: "sans-serif",
                  }}
                  className="bannername3"
                >
                  <h4
                    className="text-white bnertextmobi"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Global Healthcare 
                  </h4>
                  <h4
                    className="text-white bnertextmobi"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "800",
                      textAlign: "start",
                      fontFamily: "sans-serif",
                    }}
                  >
                    Access Anytime
                  </h4>
                  <h4
                    className="text-white bnertextmobi"
                    style={{ fontFamily: "sans-serif" }}
                  >
                    Anywhere
                  </h4>
                </div>
                <div style={{ marginRight: "34%" }}>
                  <div className="button-group btnmobi">
                    <Link
                      to="https://portal.virtualtriage.ca/doctor_register"
                      className="btn transparent"
                      style={{
                        borderRadius: "15px",
                        textTransform: "capitalize",
                        width: "150px",
                        paddingTop: "0px",
                        fontSize: "20px",
                        fontFamily: "Calibri",
                      }}
                    >
                      <b>Free Trial</b>
                    </Link>
                    <button
                      onClick={openCalendlyPopup}
                      className="btn transparent"
                      style={{
                        borderRadius: "15px",
                        textTransform: "capitalize",
                        width: "150px",
                        paddingTop: "0px",
                        fontSize: "20px",
                        fontFamily: "Calibri",
                      }}
                    >
                      <b>Get A Demo</b>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popup */}
      {/* {showPopup && (
        <div className="popup">
          <div className="popup-card">
            <button className="close-btn" onClick={handlePopupClose}>
              X
            </button>
            <div className="popup-content">
              <img
                src="/assest/images/HomeImages/logo.png"
                alt="Logo"
                className="logo"
              />
              <h2 className="text-white">Enter Your Email & Get a Chance to Win!</h2>
              <div className="slider">
                <div
                  className="slider-images"
                  style={{
                    transform: `translateX(-${
                      currentSlide * (100 / images.length)
                    }%)`,
                    width: `${images.length * 100}%`,
                  }}
                >
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Slide ${index + 1}`}
                      style={{ width: `${100 / images.length}%` }}
                    />
                  ))}
                </div>
              </div>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit">Subscribe</button>
              </form>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Banner;
